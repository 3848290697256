import React from 'react'
import { Link } from 'gatsby'

import logo_footer from '../img/logo-footer.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <img
            src={logo_footer}
            alt="Thicket of Diversity"
            style={{ width: '24em', height: '5em' }}
          />
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div style={{ maxWidth: '100vw' }} className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/support">
                        Support
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/charter">
                        Charter
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/science">
                        Science
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/field-research-station">
                        Field Research Station
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/resources">
                        Resources
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    <li>
                      <Link className="navbar-item" to="/contact">
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="https://www.bigthicket.org/pdf/CC-Privacy-Policy.pdf">
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4 social">
                <a title="Big Thicket Association" href="https://www.bigthicket.org">
                  Big Thicket Association Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
