import React from "react";
import { Link } from "gatsby";
import facebook from '../img/social/facebook.svg'
import logo from "../img/logo.svg";
import todlogo from "../img/tod-logo.svg";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <React.Fragment>
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <a
              className="navbar-item"
              href="https://www.bigthicket.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={logo} alt="Big Thicket Association" style={{ width: "240px" }} />
            </a>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-end has-text-centered">
              <a
                className="navbar-item"
                href="http://facebook.com/BigThicketAssociation"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <img src={facebook} alt="Facebook BTA" />
                </span>
                &nbsp;&nbsp;BTA
              </a>
              <a
                className="navbar-item"
                href="http://facebook.com/pages/Neches-River-Adventures/209311866070"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <img src={facebook} alt="Facebook N.R. Adventures" />
                </span>
                &nbsp;&nbsp;N.R.Adventures
              </a>
              <a
                className="navbar-item"
                href="https://www.facebook.com/NechesRiverRally"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <img src={facebook} alt="Facebook N.R. Rally" />
                </span>
                &nbsp;&nbsp;N.R. Rally
              </a>
            </div>
          </div>
        </div>
      </nav>
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-brand">
              <Link to="/" className="navbar-item" title="Thicket of Diversity Logo">
                <img src={todlogo} alt="Thicket of Diversity" style={{ width: "340px" }} />
              </Link>
            </div>
            <div className="navbar-start has-text-centered">
              <Link className="navbar-item" to="/support">
                Support
              </Link>
              <Link className="navbar-item" to="/charter">
                Charter
              </Link>
              <Link className="navbar-item" to="/science">
                Science
              </Link>
              <Link className="navbar-item" to="/field-research-station">
                Field Research Station
              </Link>
              <Link className="navbar-item" to="/resources">
                Resources
              </Link>
              <Link className="navbar-item" to="/contact">
                Contact
              </Link>
            </div>
          </div>
        </div>
      </nav>
      </React.Fragment>
    )
  }
}

export default Navbar
